import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Sidebar from "./components/Sidebar";
import NoteList from "./components/NoteList";
import NoteEditor from "./components/NoteEditor";
import EmotionGraph from "./components/EmotionGraph";
import {
  createNoteForUserDb,
  getNotesByUserIdDb,
  deleteNoteDb,
  updateNoteDb,
} from "./services/notesService";
import Note from "./models/noteModel";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import useIsMobile from "./utils/useIsMobile";
import MobileBar from "./components/MobileBar";
import SignIn from "./components/SignIn";
import generateReflection from "./services/reflectionService";
import { MoreVertical, MoreHorizontal } from "lucide-react";
import Dashboard from "./components/Dashboard";
import WeeklyReview from "./models/weeklyReviewModel";
import { getSummaryAndThemes, createReviewForUser, getNotesToSelf } from "./services/reviewService";
import { getLastSunday, getLastSaturday } from "./utils/dateUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./components/ForgotPassword";
import SignOutMenu from "./components/SignOutMenu";
import { analyzeEmotionsHume } from "./services/emotionAnalysisService";
import showdown from "showdown";
import { trackEvent } from "./services/trackUserAuthService";
import { getUserGoal, setUserGoal } from "./services/userService";
import GoalModal from "./components/GoalModal";
import Review2024 from "./components/Review2024";
import My2024 from "./components/My2024";
import { generateReview } from "./services/reviewService";
// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const parseMarkdownToHtml = (markdown) => {
  const converter = new showdown.Converter();
  const html = converter.makeHtml(markdown);
  return html;
};

function App() {
  // Create an instance of the mixpanel client

  const [notes, setNotes] = useState([]);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [user, setUser] = useState(null);
  const [goal, setGoal] = useState(null);
  const [isGeneratingReflection, setIsGeneratingReflection] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const location = useLocation();
  const [isEmotionGraphOpen, setIsEmotionGraphOpen] = useState(false);

  const noteId = location.pathname.startsWith("/note/")
    ? location.pathname.split("/note/")[1]
    : null;

  const [isImporting, setIsImporting] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState(false);

  const [lastTimestamp, setLastTimestamp] = useState(Date.now());

  const getUniqueTimestamp = () => {
    const current = Date.now();
    const timestamp = Math.max(current, lastTimestamp + 1);
    setLastTimestamp(timestamp);
    return timestamp;
  };

  const setGoalStateAndDb = async (newGoal) => {
    setGoal(newGoal);
    await setUserGoal(user.uid, newGoal);
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      if (!user && location.pathname !== "/review2024") {
        navigate("/auth");
      }
    });

    return () => unregisterAuthObserver();
  }, []);

  const updateNotesDb = async () => {
    if (user) {
      notes.forEach(async (note) => {
        await updateNoteDb(note.id, note, user.uid);
      });
    }
  };

  useEffect(() => {
    return () => {
      // unmounting app, make sure all notes are up to date in db
      updateNotesDb();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // console.log("Unloading app, updating notes in db");
      // updateNotesDb();
      event.preventDefault();
      event.returnValue = ""; // This is required for some browsers to show the confirmation dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const getMostRecentNote = (notes) => {
    return notes.sort((a, b) => new Date(b.lastEdited) - new Date(a.lastEdited))[0];
  };

  const { pathname } = useLocation();

  const fetchNotes = async () => {
    if (user) {
      console.log("fetching notes");
      const savedNotes = await getNotesByUserIdDb(user.uid);
      if (savedNotes) {
        const parsedNotes = savedNotes.map(
          (note) =>
            new Note(
              note.id,
              note.title,
              note.content,
              note.userId,
              note.lastEdited,
              note.emotions,
              note.hidden
            )
        );
        setNotes(parsedNotes);

        if (noteId) {
          setSelectedNoteIdAndNavigate(noteId);
        } else if (!isMobile && pathname !== "/new") {
          const mostRecentNote = getMostRecentNote(parsedNotes);
          setSelectedNoteIdAndNavigate(mostRecentNote?.id || null);
        }
      } else if (!isMobile) {
        handleAddNote();
      }

      if (pathname === "/new") {
        handleAddNote();
      }
    }
  };

  const fetchUserGoal = async () => {
    if (user) {
      const userGoal = await getUserGoal(user.uid);
      setGoal(userGoal);
      setShowGoalModal(userGoal === null);
    }
  };

  useEffect(() => {
    const redirectPath = localStorage.getItem("authRedirect");
    if (redirectPath === "/review2024" || pathname === "/review2024" || pathname === "/my2024") {
      return;
    }
    fetchNotes();
    fetchUserGoal();
  }, [user]);

  useEffect(() => {
    // remove empty notes that are not the selected note
    const nonEmptyNotes = notes.filter(
      (note) => note.content.length > 0 || note.title.length > 0 || note.id === selectedNoteId
    );
    const emptyNoteIds = notes
      .filter(
        (note) => note.content.length === 0 && note.title.length === 0 && note.id !== selectedNoteId
      )
      .map((note) => note.id);
    // delete empty notes from firestore
    for (const id of emptyNoteIds) {
      deleteNoteDb(id, user.uid);
    }
    setNotes(nonEmptyNotes);
  }, [selectedNoteId]);

  const handleAddNote = async () => {
    const newNote = new Note(
      getUniqueTimestamp(),
      "",
      "",
      user.uid,
      new Date(getUniqueTimestamp()).toISOString(),
      []
    );

    setNotes((prevNotes) => [...prevNotes, newNote]);
    await createNoteForUserDb(newNote, user.uid);
    setSelectedNoteIdAndNavigate(newNote.id);
  };

  const handleDeleteNote = (noteId) => {
    setNotes((prevNotes) => {
      const updatedNotes = prevNotes.filter((note) => note.id !== noteId);
      if (!isMobile) {
        if (updatedNotes.length === 0) {
          const newNote = new Note(
            getUniqueTimestamp(),
            "",
            "",
            user.uid,
            new Date(getUniqueTimestamp()).toISOString(),
            []
          );
          createNoteForUserDb(newNote, user.uid);
          setSelectedNoteIdAndNavigate(newNote.id);
          return [newNote];
        }
        if (selectedNoteId === noteId) {
          const mostRecentNote = getMostRecentNote(updatedNotes);
          setSelectedNoteIdAndNavigate(mostRecentNote?.id || null);
        }
      }
      deleteNoteDb(noteId, user.uid);
      return updatedNotes;
    });
  };

  const handleUpdateNote = (noteToUpdate, updatedFields, updateLastEdited = true) => {
    // Update the note before updating the state
    const updatedNote = noteToUpdate.update(updatedFields, updateLastEdited);

    // Update the notes state with the updated note
    setNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteToUpdate.id ? updatedNote : note))
    );

    return updatedNote;
  };

  const handleSignOut = () => {
    updateNotesDb();
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const selectedNote = notes.find((note) => note.id === selectedNoteId);

  const setSelectedNoteIdAndNavigate = (noteId) => {
    setSelectedNoteId(noteId);
    navigate(`/note/${noteId}`);
  };

  const tiptapEditorRef = useRef(null);

  const generateReflectionForSelectedNote = async () => {
    setIsGeneratingReflection(true);
    const reflection = await generateReflection(
      selectedNote.content,
      selectedNote.userId,
      selectedNote.id,
      goal
    );
    if (reflection === "No question") {
      toast("Please provide more context in your journal entry for reflection", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setIsGeneratingReflection(false);
      return;
    }
    // console.log(reflection);
    // console.log(tiptapEditorRef.current);
    if (tiptapEditorRef.current && reflection !== "") {
      await tiptapEditorRef.current.insertReflection(reflection);
    }

    setIsGeneratingReflection(false);
  };

  const generateWeeklyReview = async (forceEarlyGenerate = false) => {
    // Filter notes from the past week
    let lastSunday = forceEarlyGenerate
      ? new Date(getLastSunday().getTime() + 7 * 24 * 60 * 60 * 1000)
      : getLastSunday();
    let lastSaturday = forceEarlyGenerate
      ? new Date(getLastSaturday().getTime() + 7 * 24 * 60 * 60 * 1000)
      : getLastSaturday();

    const weeklyNotes = notes.filter((note) => {
      const noteDate = new Date(note.lastEdited);
      return noteDate >= lastSunday && noteDate <= lastSaturday;
    });

    console.log("weeklyNotes", weeklyNotes);

    if (weeklyNotes.length < 2) {
      return null;
    }

    try {
      const response = await generateReview(
        weeklyNotes,
        user.uid,
        lastSunday,
        lastSaturday,
        forceEarlyGenerate
      );

      if (!response) {
        return null;
      }
      const weeklyReview = new WeeklyReview(
        response.id,
        response.emotionBreakdown,
        response.noteCount,
        response.summary,
        response.themes,
        response.notesToSelf,
        response.reflections,
        response.goals,
        response.startDate,
        response.endDate
      );
      await createReviewForUser(weeklyReview, user.uid);

      return weeklyReview;
    } catch (error) {
      console.error("Error generating weekly review:", error);
      return null;
    }
  };

  const SidebarAndNoteList = () => {
    return (
      <>
        {isMobile ? (
          <MobileBar addNote={handleAddNote} />
        ) : (
          <Sidebar
            addNote={handleAddNote}
            deleteSelectedNote={handleDeleteNote}
            selectedNoteId={selectedNoteId}
            generateReflection={generateReflectionForSelectedNote}
            isGeneratingReflection={isGeneratingReflection}
            notes={notes}
            setSelectedNoteId={setSelectedNoteIdAndNavigate}
          />
        )}
        <NoteList
          notes={notes}
          selectedNote={selectedNote}
          setSelectedNoteId={setSelectedNoteIdAndNavigate}
          selectedNoteId={selectedNoteId}
          updateNote={handleUpdateNote}
          deleteSelectedNote={handleDeleteNote}
        />
      </>
    );
  };

  function NewNote() {
    useEffect(() => {
      // handleAddNote();
    }, []);

    return <SidebarAndNoteList />;
  }

  const handleFileImport = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    setIsImporting(true);

    try {
      const importPromises = files.map(async (file) => {
        const fileExt = file.name.toLowerCase();
        if (!fileExt.endsWith(".md") && !fileExt.endsWith(".txt")) {
          toast.error(`Skipping ${file.name} - not a markdown or text file`);
          return null;
        }

        let text = await file.text();
        let newNote;

        // Get filename without extension as title
        const title = file.name.replace(/\.(md|txt)$/i, "");

        if (fileExt.endsWith(".md")) {
          text = parseMarkdownToHtml(text);
        }

        newNote = new Note(
          getUniqueTimestamp(),
          title,
          text,
          user.uid,
          new Date(getUniqueTimestamp()).toISOString(),
          []
        );

        const emotions = await analyzeEmotionsHume(text, user.uid, newNote.id);
        newNote.emotions = emotions;

        await createNoteForUserDb(newNote, user.uid);

        return newNote;
      });

      // Wait for all imports to complete
      const importedNotes = await Promise.all(importPromises);

      // Filter out null values (skipped files) and update state once
      // console.log("importedNotes", importedNotes);
      const validNotes = importedNotes.filter((note) => note !== null);

      if (validNotes.length > 0) {
        setNotes((prevNotes) => [...prevNotes, ...validNotes]);
        // Navigate to the last valid note
        setSelectedNoteIdAndNavigate(validNotes[validNotes.length - 1].id);
        toast.success(
          `Successfully imported ${validNotes.length} file${validNotes.length > 1 ? "s" : ""}`
        );
      }

      trackEvent("Imported Files", {
        distinct_id: user.uid,
        num_notes: validNotes.length,
      });
    } catch (error) {
      console.error("Error importing files:", error);
      toast.error("Error importing files");
    } finally {
      setIsImporting(false);
    }

    // Reset the file input
    event.target.value = "";
  };

  const handleSaveGoal = async (newGoal) => {
    // console.log("Saving goal:", newGoal);
    if (user) {
      await setUserGoal(user.uid, newGoal);
      setGoal(newGoal);
    }
  };

  const SignOutMenuComponent = (
    <SignOutMenu
      onSignOut={handleSignOut}
      user={user}
      goal={goal}
      setGoal={setGoalStateAndDb}
      handleFileImport={handleFileImport}
    />
  );

  return (
    <>
      <ToastContainer />
      <div
        className="flex w-screen h-screen bg-[#FCF9FA]"
        data-route={location.pathname}
        data-emotion-graph={isEmotionGraphOpen ? "open" : "closed"}
      >
        <div className="route-container flex flex-row flex-auto">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  {SignOutMenuComponent}
                  <SidebarAndNoteList />
                  {!isMobile && selectedNoteId && (
                    <NoteEditor
                      ref={tiptapEditorRef}
                      note={selectedNote}
                      updateNote={handleUpdateNote}
                      generateReflection={generateReflectionForSelectedNote}
                      isGeneratingReflection={isGeneratingReflection}
                    />
                  )}
                </>
              }
            />
            <Route path="/review2024" element={<Review2024 user={user} />} />
            <Route path="/my2024" element={<My2024 user={user} />} />
            <Route
              path="/note/:noteId"
              element={
                <>
                  {SignOutMenuComponent}
                  {!isMobile && (
                    <>
                      <Sidebar
                        addNote={handleAddNote}
                        deleteSelectedNote={handleDeleteNote}
                        selectedNoteId={selectedNoteId}
                        generateReflection={generateReflectionForSelectedNote}
                        isGeneratingReflection={isGeneratingReflection}
                        notes={notes}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                      />
                      <NoteList
                        notes={notes}
                        selectedNote={selectedNote}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                        selectedNoteId={selectedNoteId}
                        updateNote={handleUpdateNote}
                        deleteSelectedNote={handleDeleteNote}
                      />
                    </>
                  )}
                  <NoteEditor
                    ref={tiptapEditorRef}
                    note={selectedNote}
                    updateNote={handleUpdateNote}
                    generateReflection={generateReflectionForSelectedNote}
                    isGeneratingReflection={isGeneratingReflection}
                  />
                </>
              }
            />
            <Route path="/new" element={<NewNote />} />
            <Route
              path="/graph"
              element={
                <>
                  {SignOutMenuComponent}
                  {!isMobile ? (
                    <>
                      <Sidebar
                        addNote={handleAddNote}
                        deleteSelectedNote={handleDeleteNote}
                        selectedNoteId={selectedNoteId}
                        generateReflection={generateReflectionForSelectedNote}
                        isGeneratingReflection={isGeneratingReflection}
                        notes={notes}
                        setSelectedNoteId={setSelectedNoteIdAndNavigate}
                      />
                      <Dashboard notes={notes} user={user} generateReview={generateWeeklyReview} />
                      <div className="emotion-graph-container flex-1">
                        <EmotionGraph
                          notes={notes}
                          setSelectedNoteId={setSelectedNoteIdAndNavigate}
                          setIsEmotionGraphOpen={setIsEmotionGraphOpen}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <MobileBar addNote={handleAddNote} />
                      <div className="flex flex-col">
                        <div className="emotion-graph-container flex-1">
                          <EmotionGraph
                            notes={notes}
                            setSelectedNoteId={setSelectedNoteIdAndNavigate}
                            setIsEmotionGraphOpen={setIsEmotionGraphOpen}
                          />
                        </div>
                        <Dashboard
                          notes={notes}
                          user={user}
                          generateReview={generateWeeklyReview}
                        />
                      </div>
                    </>
                  )}
                </>
              }
            />
            <Route path="/auth" element={<SignIn setUser={setUser} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </div>
        <GoalModal
          isOpen={showGoalModal}
          handleSkip={async () => {
            setShowGoalModal(false);
            await setUserGoal(user.uid, ["", "", ""]);
            setGoal(["", "", ""]);
          }}
          onSaveGoal={handleSaveGoal}
          setShowGoalModal={setShowGoalModal}
        />
        {isImporting && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 flex flex-col items-center space-y-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              <p className="text-lg">Importing notes...</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
