import React, { useState, forwardRef, useEffect, useImperativeHandle, useCallback } from "react";
import { EditorContent, useEditor, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from '@tiptap/extension-link';
import Reflection from "../extensions/Reflection";
import { LoaderCircle, Heart, Check, Sparkle, ChevronLeft } from "lucide-react";
import { analyzeEmotionsHume } from "../services/emotionAnalysisService";
import { updateNoteDb } from "../services/notesService";
import { setSaveTimeout, setAnalyzeTimeout, setShowPromptTimeout } from "../utils/timeoutManager";
import { EMOTION_COLORS } from "../constants/emotionColors";
import useIsMobile from "../utils/useIsMobile";
import { useNavigate } from "react-router-dom";
import "../styles/TiptapEditor.css";
import Placeholder from "@tiptap/extension-placeholder";
import { generateTitleFromContent } from "../services/titleGenerationService";
import ReflectionMobile from "../extensions/ReflectionMobile";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import { InputRule } from "@tiptap/core";
import { QUESTIONS } from "../services/review2024Service";

const TiptapEditor = forwardRef(
  (
    {
      note,
      updateNote,
      generateReflection,
      isGeneratingReflection,
      is2024Review = false,
      numQuestions = null,
      handleComplete2024Review = null,
    },
    ref
  ) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isAnalyzing, setIsAnalyzing] = useState(false);
    const [hasGeneratedReflection, setHasGeneratedReflection] = useState(() => {
      return sessionStorage.getItem(`reflection-${note.id}`) === "true";
    });
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
      sessionStorage.setItem(`reflection-${note.id}`, hasGeneratedReflection);
    }, [hasGeneratedReflection, note.id]);

    const editor = useEditor({
      extensions: [
        StarterKit.configure({
          horizontalRule: false,
        }),
        Link.configure({
          openOnClick: true,
          autolink: true,
          linkOnPaste: true,
          HTMLAttributes: {
            class: 'cursor-pointer text-blue-500 hover:text-blue-600 underline',
          },
        }),
        isMobile ? ReflectionMobile : Reflection.configure({ userId: note.userId }),
        Placeholder.configure({
          placeholder: ({ editor }) => {
            return "How do you feel today?";
          },
        }),
        HorizontalRule.configure({
          HTMLAttributes: {
            class: "horizontal-rule",
          },
          addInputRules() {
            return [
              new InputRule({
                find: /^(?:---|___)\s$/,
                handler: ({ state, range }) => {
                  const { tr } = state;
                  tr.delete(range.from, range.to);
                  tr.replaceSelectionWith(this.type.create()).scrollIntoView();
                },
              }),
            ];
          },
        }),
      ],
      content: note?.content || "",
      editorProps: {
        attributes: {
          class: "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none",
        },
        handleKeyDown: (view, event) => {
          if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
            event.preventDefault();
            return true;
          }
          return false;
        },
      },
      onUpdate: ({ editor }) => {
        const reflectionNodes = [];
        editor.state.doc.descendants((node, pos) => {
          if (node.type.name === "reflection") {
            reflectionNodes.push({ node, pos });
          }
        });

        const lastNode = editor.state.doc.lastChild;
        if (lastNode.type.name === "reflection") {
          editor.commands.insertContentAt(editor.state.doc.content.size, "<p></p>");
        }

        const newContent = editor.getHTML();
        handleContentChange(newContent);
      },
    });

    useEffect(() => {
      if (editor) {
        editor.commands.focus("end");
      }
    }, [editor, note?.id]);

    useEffect(() => {
      // changing note
      if (editor && note) {
        if (editor.getHTML() !== note.content) {
          editor.commands.setContent(note.content || "");
          setShowPrompt(false);
          setHasGeneratedReflection(false);
        }
      }
    }, [editor, note?.id]);

    const insertReflection = async (reflection) => {
      editor
        .chain()
        .focus()
        .insertContent(`<reflection class="reflection-node">${reflection}</reflection><p></p>`)
        .run();
      setHasGeneratedReflection(true);
      setShowPrompt(false);
      const updatedNote = updateNote(note, { content: editor.getHTML() });
      await updateNoteDb(note.id, updatedNote, note.userId);
    };

    useImperativeHandle(ref, () => ({
      insertReflection,
    }));

    const adjustTextareaHeight = () => {
      const textarea = document.querySelector(".tiptap-editor-title");
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      }
    };

    useEffect(() => {
      window.addEventListener("resize", adjustTextareaHeight);
      return () => {
        window.removeEventListener("resize", adjustTextareaHeight);
      };
    }, []);

    useEffect(() => {
      adjustTextareaHeight();
    }, [note?.title]);

    const handleKeyDown = useCallback(
      (event) => {
        if (
          (event.key === "Tab" && hasGeneratedReflection && showPrompt) ||
          (event.key === "Enter" && (event.metaKey || event.ctrlKey))
        ) {
          event.preventDefault();
          setShowPrompt(false);
          generateReflection();
          setShowPrompt(false);
        }
      },
      [hasGeneratedReflection, showPrompt, generateReflection]
    );

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener("keydown", handleKeyDown);
    }, [handleKeyDown]);

    // useEffect(() => {
    //   const handleFirstKeyPress = (e) => {
    //     // Ignore if we're already focused on an input or if it's a modifier key
    //     if (
    //       document.activeElement.tagName === "TEXTAREA" ||
    //       document.activeElement.className === "ProseMirror" ||
    //       e.key === "Shift" ||
    //       e.key === "Control" ||
    //       e.key === "Alt" ||
    //       e.key === "Meta"
    //     ) {
    //       return;
    //     }

    //     // Focus the editor when a key is pressed
    //     editor?.commands.focus("end");
    //   };

    //   window.addEventListener("keydown", handleFirstKeyPress);
    //   return () => window.removeEventListener("keydown", handleFirstKeyPress);
    // }, [editor]);

    if (!note || !editor) return null;

    const formatDate = (date) => {
      const d = new Date(date);
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const month = months[d.getMonth()];
      const day = d.getDate();
      const year = d.getFullYear();
      const hours = d.getHours().toString().padStart(2, "0");
      const minutes = d.getMinutes().toString().padStart(2, "0");

      return `${month} ${addOrdinalSuffix(day)} ${year} at ${hours}:${minutes}`;
    };

    const addOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return day + "th";
      switch (day % 10) {
        case 1:
          return day + "st";
        case 2:
          return day + "nd";
        case 3:
          return day + "rd";
        default:
          return day + "th";
      }
    };

    const generateTitle = async (content, currentNote) => {
      try {
        const generatedTitle = await generateTitleFromContent(content, note.userId, note.id);
        if (generatedTitle === "No title") {
          return;
        }
        const updatedNote = updateNote(currentNote || note, { title: generatedTitle });
        await updateNoteDb(note.id, updatedNote, note.userId);
      } catch (error) {
        console.error("Failed to generate title:", error);
      }
    };

    const handleTitleChange = (e) => {
      const newTitle = e.target.value;
      const updatedNote = updateNote(note, { title: newTitle });

      setIsSaving(true);
      setSaveTimeout(
        note.id,
        async () => {
          await updateNoteDb(note.id, updatedNote, note.userId);
          setIsSaving(false);
        },
        1000
      );
    };

    const handleContentChange = (newContent) => {
      let updatedNote = updateNote(note, { content: newContent });
      setShowPrompt(false);
      if (hasGeneratedReflection) {
        setShowPromptTimeout(
          note.id,
          () => {
            const selection = editor.state.selection;
            const currentNode = selection.$anchor.parent;
            const hasContent =
              currentNode.type.name === "paragraph" && currentNode.textContent.trim().length > 0;
            setShowPrompt(hasContent);
          },
          4000
        );
      }

      setIsSaving(true);
      setSaveTimeout(note.id, async () => {
        await updateNoteDb(note.id, updatedNote, note.userId);
        setIsSaving(false);
        // console.log("Checking reflection state:", hasGeneratedReflection);
        // console.log("SessionStorage:", sessionStorage.getItem(`reflection-${note.id}`));
      });

      setIsAnalyzing(true);
      setAnalyzeTimeout(
        note.id,
        async (signal) => {
          const emotions =
            newContent.length > 5
              ? await analyzeEmotionsHume(note.title + "\n" + newContent, note.userId, note.id)
              : [];
          updatedNote = updateNote(updatedNote, { emotions }, false);
          // console.log("updated note emotions", updatedNote);
          await updateNoteDb(note.id, updatedNote, note.userId);
          setIsAnalyzing(false);
        },
        10000
      );

      if (!note.title.trim() && newContent.length > 5) {
        setAnalyzeTimeout(
          `title-${note.id}`,
          async () => {
            await generateTitle(newContent, updatedNote);
          },
          20000
        );
      }
    };

    return (
      <div className="tiptap-editor-container">
        <div className="tiptap-editor-header">
          <p
            onClick={() => {
              if (isMobile) {
                navigate(`/`);
              }
            }}
          >
            {isMobile ? (
              <button className="icon-button">
                <ChevronLeft size={16} className="text-[#3838688C]" />
              </button>
            ) : null}
          </p>
          <p className="tiptap-editor-date">{formatDate(note.lastEdited)}</p>

          <p className="saving-indicator">
            <span className={`saving-state ${isSaving ? "active" : ""}`}>
              Saving{" "}
              <span>
                <LoaderCircle className="animate-spin mb-[3px]" size={16} />
              </span>
            </span>
            <span className={`saving-state ${isAnalyzing && !isSaving ? "active" : ""}`}>
              Analyzing{" "}
              <span>
                <Heart
                  className="mb-[3px] animate-[pulse_1.25s_cubic-bezier(0.4,0,0.6,1)_infinite]"
                  size={16}
                />
              </span>
            </span>
            <span className={`saving-state ${!isAnalyzing && !isSaving ? "active" : ""}`}>
              Saved{" "}
              <span>
                <Check className="mb-[3px]" size={16} />
              </span>
            </span>
          </p>
        </div>

        <div className="editor-wrapper">
          <div className="tiptap-editor-emotion-container">
            {note.emotions?.map((emotion, index) => (
              <span
                key={index}
                className="tiptap-editor-emotion animate-in"
                style={{
                  color: EMOTION_COLORS[emotion]?.text || "#000000",
                  backgroundColor: EMOTION_COLORS[emotion]?.background || "#FFFFFF",
                }}
              >
                {emotion}
              </span>
            ))}
          </div>

          <textarea
            className={`tiptap-editor-title ${note.title ? "has-content" : ""}`}
            value={note.title}
            onChange={(e) => {
              handleTitleChange(e);
              e.target.style.height = "auto";
              e.target.style.height = e.target.scrollHeight + "px";
            }}
            placeholder="New note"
            rows="1"
          />
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className="bubble-menu">
              <button
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive("bold") ? "is-active" : ""}
              >
                Bold
              </button>
              <button
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive("italic") ? "is-active" : ""}
              >
                Italic
              </button>
              <button
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive("strike") ? "is-active" : ""}
              >
                Strike
              </button>
              <button
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive("blockquote") ? "is-active" : ""}
              >
                Quote
              </button>
            </div>
          </BubbleMenu>
          <EditorContent editor={editor} />
          {hasGeneratedReflection && showPrompt && !is2024Review && (
            <div className="reflection-prompt-container">
              <Sparkle className="reflection-sparkle" />
              <div className="reflection-prompt">
                Would you like another question? (<kbd>Tab</kbd> or <kbd>⌘</kbd>+<kbd>Enter</kbd>)
              </div>
            </div>
          )}
          {is2024Review && (
            <div className="review2024-container flex flex-row gap-2">
              {numQuestions < QUESTIONS.length ? (
                <button
                  onClick={generateReflection}
                  className="continue-button"
                  disabled={isGeneratingReflection}
                >
                  <Sparkle className="reflection-sparkle" />
                  <div className="reflection-prompt">Continue reflecting</div>
                </button>
              ) : (
                <button
                  onClick={handleComplete2024Review}
                  className="continue-button complete-button"
                  disabled={isGeneratingReflection}
                >
                  <Sparkle className="reflection-sparkle" />
                  <div className="reflection-prompt">Complete my reflection</div>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

TiptapEditor.displayName = "TiptapEditor";

export default TiptapEditor;
